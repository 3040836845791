// Import ROBOTO Font
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

@mixin grid-center-last($columns) {
    $total_columns: $columns * 2;
    $span: $total_columns / $columns;

    $offset: $columns - 1;
    $offset-over: $columns;

    $nth-child: if($columns % 2 == 0, "odd", "even");
    $nth-child-over: if($columns % 2 == 1, "odd", "even");

    display: grid;
    grid-template-columns: repeat($total_columns, 1fr);

    & > * {
        grid-column: span $span;

        &:nth-last-child(#{$offset}):nth-child(#{$nth-child}) {
            grid-column: $span / span $span;
        }

        &:nth-last-child(#{$offset-over}):nth-child(#{$nth-child-over}) {
            grid-column: span $span;
        }
    }
}

$app-primary: #6c8a0f;
$app-secondary: #006ef5;
$app-dark: #6b6867;
$app-muted: #83807f;

body {
    font-family: "Roboto", sans-serif;
}

// Sobrescreve Bootstrap
.bg-primary,
.btn-primary {
    background-color: $app-primary !important;
}

.btn-primary,
.border-primary {
    border-color: $app-primary !important;
}

.text-primary {
    color: $app-primary !important;
}

.bg-secondary,
.btn-secondary {
    background-color: $app-secondary !important;
}

.btn-secondary,
.border-secondary {
    border-color: $app-secondary !important;
}

.text-secondary {
    color: $app-secondary !important;
}

.bg-dark,
.btn-dark {
    background-color: $app-dark !important;
}

.btn-dark,
.border-dark {
    border-color: $app-dark !important;
}

.text-dark {
    color: $app-dark !important;
}

.bg-muted,
.btn-muted {
    background-color: $app-muted !important;
}

.btn-muted,
.border-muted {
    border-color: $app-muted !important;
}

.text-muted {
    color: $app-muted !important;
}

// Utilities
.px-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}

.px-20 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
}

.mx-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
}

.mx-20 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
}

.mt-10 {
    margin-top: 6rem !important;
}

.my-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}

.word-spacing-5px {
    word-spacing: 5px !important;
}
.font-size-2 {
    font-size: 2rem !important;
}

.font-size-1 {
    font-size: 1rem !important;
}

.font-size-1-25 {
    font-size: 1.25rem !important;
}

.max-width-350px {
    max-width: 350px !important;
}

.min-width-350px {
    min-width: 350px !important;
}

.max-width-500px {
    max-width: 500px !important;
}

.max-width-750px {
    max-width: 750px !important;
}

.min-width-500px {
    min-width: 500px !important;
}

.height-300px {
    height: 300px !important;
}

.max-height-100px {
    max-height: 100px !important;
}

.max-height-150px {
    max-height: 150px !important;
}

.max-height-300px {
    max-height: 300px !important;
}

.width-max-content {
    width: max-content !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

.opacity-75 {
    opacity: 0.75;
}

.opaque-black-75,
.opaque-black-60 {
    position: relative;
}

.opaque-black-75:before,
.opaque-black-60:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
}

.opaque-black-75:before {
    opacity: 0.75;
}

.opaque-black-60:before {
    opacity: 0.60;
}

.opaque-primary-25 {
    position: relative;
}

.opaque-primary-25:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $app-primary;
    opacity: 0.25;
}

.z-index-1 {
    z-index: 1;
}

.position-absolute.center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.position-absolute.center-x {
    left: 50%;
    transform: translateX(-50%);
}

.gapx-4 {
    column-gap: 1.5rem !important;
}

.grid-template-columns-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-template-columns-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-template-columns-3.center-last {
    @include grid-center-last(3);
}

.divider-primary {
    background-color: $app-primary;
    height: 2px !important;
    opacity: 1;
}
// GENERAL
a {
    text-decoration: none;
    color: inherit;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

// INSTITUTO DE OLHOS | HOME
#o-instituto-secao {
    transform: translateY(-30px);

    .box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;

        li {
            background-color: #e9e9e9;
            color: #6c8a0f;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-weight: bolder;
            padding-bottom: 1rem;
        }
    }
}

.map {
    height: 400px;
    width: 100%;
}

// Large
@media only screen and (max-width: 1200px) {
    html {
        font-size: 13px;
    }
}

// Medium
@media only screen and (max-width: 992px) {
    html {
        font-size: 10px;
    }

    .max-width-md-125px {
        max-width: 125px !important;
    }

    .max-width-md-300px {
        max-width: 300px !important;
    }

    .min-width-md-300px {
        min-width: 300px !important;
    }
}

// Small
@media only screen and (max-width: 768px) {
    html {
        font-size: 8px;
    }

    .text-small-center {
        text-align: center !important;
    }

    .max-width-sm-150px {
        max-width: 150px !important;
    }

    .max-height-sm-400px {
        max-height: 400px !important;
    }

    .mx-sm-auto {
        margin: auto !important;
    }

    .p-small-0 {
        padding: 0 !important;
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important;
    }
}

// X-Small
@media only screen and (max-width: 576px) {
    html {
        font-size: 12px;
    }

    .d-xs-none {
        display: none !important;
    }

    .d-xs-block {
        display: block !important;
    }

    .flex-xs-column {
        flex-direction: column;
    }

    .pe-xs-0 {
        padding-right: 0 !important;
    }

    .p-xs-0 {
        padding: 0 !important;
    }

    .px-xs-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .py-xs-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .text-xs-center {
        text-align: center !important;
    }

    .mx-xs-auto {
        margin: auto !important;
    }

    .my-xs-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .my-xs-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .mx-xs-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mt-xs-5 {
        margin-top: 3rem !important;
    }

    .height-xs-300px {
        height: 300px !important;
    }

    .max-width-xs-300px {
        max-width: 300px !important;
    }

    .grid-template-columns-xs-1 {
        grid-template-columns: repeat(1, 1fr);
    }

    .grid-template-columns-xs-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-template-columns-xs-2.center-last {
        @include grid-center-last(2);
    }

    #main-menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        transform: translateX(-100%);

        .content {
            flex-direction: column;
            background: white;
            height: 100vh;
            justify-content: flex-start !important;
            width: 60vw;

            img {
                margin: 15px auto;
                max-width: 90% !important;
            }

            ul {
                background-color: $app-primary;
                flex-direction: column;
                flex: 1;
                padding-top: 10px !important;
                margin: 0;

                li {
                    font-size: 16px;
                    color: white !important;
                    margin: 6px 0;
                }
            }
        }

        #phone-box {
            left: 0;
            flex-direction: column;
            width: 60vw;
            min-width: unset !important;
            padding: 1.5rem !important;

            h6 {
                margin: 15px 0 0 !important;
            }
        }
    }
}
